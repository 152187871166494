import { computed, readonly, ref } from 'vue';
import { orderBy } from 'lodash';
import { AxiosError } from 'axios';
import { AtomStatus, ICatalogueAtomFilters } from '@/domains/atom';
import * as CatalogueService from '@/services/api/catalogue';
import { ICatalogueAtomsGetParams } from '@/services/api/catalogue';
import { formatAtomsByMyCompanyProvider, formatRequestProvidersByMyCompany } from '@/utils/atom';
import * as LxpAssignmentService from '@/services/api/lxp-assignment';
import { IPagination } from '@/domains/common';

export type IAssignable = {
  isAssigned: boolean
  assignmentUserId: number | null;
}

export interface IPageableAtoms<T> {
  pagination: IPagination,
  atoms: T[],
}
interface IUseCatalogueAtomsSectionParams<T> {
  limit?: number,
  fetchAtomsMethod(options: ICatalogueAtomsGetParams): Promise<IPageableAtoms<T>>
}

export function useCatalogueAtomsPageable<T>({
  limit: initialLimit = 20,
  fetchAtomsMethod,
}: IUseCatalogueAtomsSectionParams<T>) {
  const items = ref<Array<T & IAssignable>>([]);
  const isLoading = ref<boolean>(false);
  const error = ref<null | number>(null);

  // Pagination refs
  const currentPage = ref<number>(1);
  const limit = ref<number>(initialLimit);
  const total = ref<number>(0);

  const fetchedAtomsCount = computed(() => items.value.length);

  const hasMoreAtoms = computed(() => total.value > fetchedAtomsCount.value);

  const formatDurations = (filterDurations: any[]) => {
    let durations: Array<Number | null> = [];
    filterDurations.forEach((duration) => {
      durations = durations.concat(duration);
    });

    durations = orderBy(durations);
    return durations.length ? [durations[0], durations[durations.length - 1]] : [];
  };
  const mapIsAssignedToAtoms = (atoms: any[]) => atoms
    .map((atom: any) => {
      const isAssigned = atom.assignments && atom.assignments.length > 0;
      let assignmentUserId: number | null = null;
      if (isAssigned) {
        assignmentUserId = atom.assignments[0].assignmentUserId as number;
      }
      return ({
        ...atom,
        isAssigned,
        assignmentUserId,
      });
    });

  const fetchAtoms = async ({
    search, sort, providers, categories, durations, atomNames,
  }: ICatalogueAtomFilters) => {
    isLoading.value = true;
    try {
      const fetchAtomsOptions = {
        page: currentPage.value,
        limit: limit.value,
        statuses: [AtomStatus.PUBLISHED],
        search,
        sort,
        providers: formatRequestProvidersByMyCompany(providers),
        categories: categories || [],
        duration: formatDurations(durations),
        atomNames: [...atomNames],
      };

      const { atoms, pagination } = await fetchAtomsMethod({
        params: fetchAtomsOptions,
      } as CatalogueService.ICatalogueAtomsGetParams);

      const atomsWithIsAssigned = mapIsAssignedToAtoms(atoms);

      items.value = pagination.page === 1
        ? formatAtomsByMyCompanyProvider(atomsWithIsAssigned)
        : [...items.value, ...formatAtomsByMyCompanyProvider(atomsWithIsAssigned)];
      currentPage.value = pagination.page;
      limit.value = pagination.limit;
      total.value = pagination.total;
    } catch (err) {
      error.value = (err as AxiosError).response?.status ?? null;
    } finally {
      isLoading.value = false;
    }
  };

  const fetchAtomsWithLoadingFlag = async (filters: ICatalogueAtomFilters) => {
    await fetchAtoms(filters);
  };

  const fetchNextPage = async (filters: ICatalogueAtomFilters) => {
    if (hasMoreAtoms.value) {
      currentPage.value += 1;
      await fetchAtoms(filters);
      return true;
    }

    return null;
  };

  // TODO: перенести в useAssignment, переименовать ручку в atomAssign
  const assignAtom = async ({ id: atomId, isRecommendations }:
    { id: UUID; isRecommendations: boolean }) => {
    try {
      const { assignmentUserId } = await LxpAssignmentService.userTrackAssign({
        catalogueAtomId: atomId,
        data: {
          onRecommendation: isRecommendations,
        },
      });
      return [null, assignmentUserId];
    } catch (err) {
      error.value = (err as AxiosError).response?.status ?? null;
      return [err, null];
    }
  };

  // В будущем фильтры будут хранится как состояние, функция будет очищать данные и фильтры
  const resetData = () => {
    items.value = [];
    total.value = 0;
    currentPage.value = 1;
  };

  return {
    items: readonly(items),
    fetchedAtomsCount: readonly(fetchedAtomsCount),
    error: computed(() => error.value),
    limit: computed(() => limit.value),
    total: readonly(total),
    isEmpty: computed(() => !isLoading.value && items.value.length === 0),
    isLoading: computed(() => isLoading.value),
    currentPage: computed(() => currentPage.value),
    hasMoreAtoms,
    resetData,
    fetchNextPage,
    fetchAtoms,
    assignAtom,
    fetchAtomsWithLoadingFlag,
    mapIsAssignedToAtoms,
  };
}
